.app-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    background-color: #333;
    color: #fff;
    flex-wrap: wrap;
}

.logo {
    flex: 1;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.side-panel {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
}

.nav-links {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
    justify-content: flex-end;
}

.navigation ul {
    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navigation li {
    margin: 0 15px;
}

.navigation a {
    color: #fff;
    text-decoration: none;
}

.data-source-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.data-source-item {
    position: relative;
}

.data-source-name {
    cursor: pointer;
    position: relative;
    display: inline-block;
}

.data-entry-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: none;
    position: absolute;
    background-color: #333;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}

.data-source-item:hover .data-entry-list,
.data-source-item:focus-within .data-entry-list {
    display: block;
}

.data-entry-list li {
    padding: 5px 10px;
}

.data-entry-list li a {
    color: #fff;
    text-decoration: none;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .app-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .navigation {
        flex-direction: column;
        align-items: flex-start;
    }

    .navigation ul {
        flex-direction: column;
        width: 100%;
    }

    .side-panel {
        flex-direction: column;
        width: 100%;
    }

    .nav-links {
        flex-direction: column;
        width: 100%;
        align-items: flex-start;
    }
}
