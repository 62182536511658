.fundamentals-documentation {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.card {
    margin-bottom: 20px;
}

.card-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.tabs {
    margin-top: 20px;
}

pre {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
}

.btn-primary {
    margin-right: 10px;
}
