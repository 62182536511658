.search-bar-container {
    position: relative;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
}

.search-input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.suggestions-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
    font-size: 16px;
}

.suggestion-item:hover {
    background-color: #f1f1f1;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 8px;
}
