.stocks-container {
    padding: 20px;
}

.search-bar-container {
    margin-bottom: 20px;
}

.filter-form {
    padding: 15px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.financial-data-section {
    margin-top: 20px;
}

.card-custom {
    margin-bottom: 20px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.filter-form label {
    font-weight: bold;
    color: #555;
}

.button-primary {
    margin-top: 15px;
}

h3 {
    color: #333;
    margin-bottom: 20px;
}

.table-container {
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #ffffff;
}

.suggestion-item:hover {
    cursor: pointer;
    background-color: #f0f0f0;
}

/* General container for the Stocks page */
.home-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* Card styling */
.card-custom {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    padding: 20px;
}

.card-custom h3 {
    font-size: 1.25rem;
    margin-bottom: 15px;
    color: #333;
}

/* Style for the financial data section */
.selected-symbols {
    margin-top: 20px;
}

.selected-symbols h3 {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
    border-bottom: 2px solid #dcdcdc;
    padding-bottom: 10px;
}

/* Tab styling */
#financial-tabs .nav-link {
    color: #007bff;
    border-radius: 5px;
    padding: 8px 15px;
    font-weight: 500;
}

#financial-tabs .nav-link.active {
    background-color: #007bff;
    color: white;
    font-weight: bold;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

/* DataTable button styling */
.button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
}

.button-container .btn {
    font-weight: bold;
    background-color: #28a745;
    color: white;
}

.button-container .btn:hover {
    background-color: #218838;
}

/* View Details button */
.table .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
    padding: 5px 10px;
    font-size: 0.9rem;
}

.table .btn-primary:hover {
    background-color: #0056b3;
    border-color: #0056b3;
}

/* Styling for the table and its headers */
.table {
    margin-bottom: 0;
    border: 1px solid #ddd;
}

.table th, .table td {
    padding: 12px;
    text-align: left;
    border-top: 1px solid #dee2e6;
    color: #333;
}

.table th {
    background-color: #f8f9fa;
    color: #333;
    font-weight: bold;
}

.table td {
    background-color: #fff;
}

/* Modal styling */
.modal-content {
    border-radius: 8px;
    padding: 20px;
}

.modal-header {
    border-bottom: none;
}

.modal-title {
    font-weight: bold;
    font-size: 1.25rem;
}

.modal-footer .btn-secondary {
    background-color: #6c757d;
    color: #fff;
}

.modal-footer .btn-secondary:hover {
    background-color: #5a6268;
}
