.account-container {
    max-width: 1200px;
    margin: auto;
}

.card-custom {
    width: 100%;
}

.chart-container {
    width: 100%;
    height: 400px;
}

.bar-chart {
    height: 100%;
}


/* Pagination styling */
.pagination {
    display: flex;
    justify-content: center;
    padding: 10px;
    list-style-type: none;
    margin: 0;
}

.pagination li {
    margin: 0 5px;
}

.pagination a {
    color: #fff;
    background-color: #007bff;
    border-radius: 5px;
    padding: 5px 10px;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
}

.pagination a:hover {
    background-color: #0056b3;
}

.pagination .active a {
    background-color: #28a745;
    pointer-events: none;
    cursor: default;
}

.pagination .disabled a {
    background-color: #6c757d;
    pointer-events: none;
    cursor: default;
}
