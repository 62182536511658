.metadata-explorer {
    padding: 20px;
}

.docs-column {
    padding-right: 20px;
}

.card-custom {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.data-table-container {
    overflow-x: auto;
    margin-top: 15px;
}

.visualization-section {
    margin-top: 20px;
}
