.card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px 0;
}

.card-title {
    margin-top: 0;
}

.card-content ul {
    list-style-type: none;
    padding-left: 0;
}

.card-content ul li {
    margin: 5px 0;
}
