.api-interface {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.card {
    margin-bottom: 20px;
}

.card-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.api-interface ul {
    list-style-type: none;
    padding-left: 0;
}

.table-of-contents ul {
    list-style-type: none;
    padding-left: 0;
}

.table-of-contents ul li {
    cursor: pointer;
    color: #007bff; /* Makes list items appear as navigable links */
}

.table-of-contents ul li:hover {
    text-decoration: underline; /* Underlines text on hover for better visibility */
}

pre {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
    overflow: auto; /* Added for better code snippet scrolling */
}

.btn-primary {
    margin-right: 10px;
}

.chart-container {
    width: 100%;
    height: 400px;
}

.bar-chart {
    height: 100%;
}
